import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environment';
import { TealiumUtagService } from '@garmin-avcloud/avcloud-web-utils';
import { FullTemplateComponent } from './core/components/full-template/full-template.component';

@Component({
  selector: 'pilot-root',
  templateUrl: './app.component.html',
  providers: [TealiumUtagService],
  imports: [FullTemplateComponent],
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly tealium = inject(TealiumUtagService);

  ngOnInit(): void {
    this.tealium.setConfig(environment.tealiumConfig);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tealium.view({
          tealium_event: 'pilotweb_load',
          page_type: event.urlAfterRedirects.toLowerCase(),
          page_name: (window as any).document.title,
        });
      }
    });
  }
}
