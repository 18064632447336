import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environment';
import {
  AVC_MAPS_DISPLAY_CONFIG,
  type AvcMapsDisplayConfig,
} from '@garmin-avcloud/avc-maps-display';
import {
  CAPS_SERVICE_CONFIG_TOKEN,
  type CapsServiceConfig,
  JSON_SYNC_SERVICE_CONFIG_TOKEN,
  type JsonSyncServiceConfig,
} from '@garmin-avcloud/avcloud-web-utils';
import {
  type FeaturesServiceConfig,
  featuresServiceConfigToken,
} from '@garmin-avcloud/avcloud-web-utils/feature';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import PDFMerger from 'pdf-merger-js/browser';
import { provideAuth } from './auth.providers';
import { provideGeneratedApiConfigs } from './generated-api.providers';
import { provideInterceptors } from './interceptors.providers';
import { redirectDevToTest } from './provider.utils';
import { provideSentry } from './sentry.providers';
import { provideSettings } from './settings.providers';
import { provideSubscriptions } from './subscriptions.providers';

export const provideCore = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideAuth(),
    provideSubscriptions(),
    provideSettings(),
    provideGeneratedApiConfigs(),
    provideInterceptors(),
    provideSentry(),

    // misc
    {
      provide: AVC_MAPS_DISPLAY_CONFIG,
      useValue: {
        clientId: 'AVC_PILOT_WEB_USER',
        mapAssetsPath: 'map-assets',
        environment: environment.name,
        capsUrl: `${environment.garmin.aviation.workerApiHost}/caps`,
        chartsUrl: `${environment.garmin.aviation.workerApiHost}/charts`,
      } as AvcMapsDisplayConfig,
    },
    {
      provide: CAPS_SERVICE_CONFIG_TOKEN,
      useValue: {
        baseUrl: 'https://dcaps.dciwx.com/caps',
        subscriptionType: 'com.garmin.phoenix.b2b',
        deviceId: '🧢',
        customerId: '🧢',
        transactionKey: '🧢',
      } as CapsServiceConfig,
    },
    {
      provide: featuresServiceConfigToken,
      useValue: {
        gatewayUrl: environment.garmin.aviation.gatewayHost,
        serviceApiPath: '/v1/feature-flags',
        features: Object.values(FeatureFlag),
      } as FeaturesServiceConfig,
    },
    {
      provide: JSON_SYNC_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
      } as JsonSyncServiceConfig,
    },

    PDFMerger, // FIXME: this probably doesn't belong here
  ]);
