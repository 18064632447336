import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import {
  HTTP_INTERCEPTOR_CONFIG_TOKEN,
  type HttpInterceptorConfig,
  HttpInterceptorService,
} from '@garmin-avcloud/avcloud-web-utils';
import { CustomerIdInjectorService } from '@shared/services/customer-id-injector/customer-id-injector.service';
import { errorInterceptor } from '../interceptor/error-interceptor/error-interceptor';

export const provideInterceptors = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomerIdInjectorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTOR_CONFIG_TOKEN,
      useValue: {
        ignoreList: [
          // Adding the bearer token is not allowed on these CORS requests
          'https://dcaps.dciwx.com/caps',
          'https://dcache.dciwx.com/caps/',
          'https://static.garmincdn.com/', // ie, stock aircraft images
        ],
        clientId: 'AVC_PILOT_WEB_USER',
      } as HttpInterceptorConfig,
    },
    provideHttpClient(withInterceptors([errorInterceptor]), withInterceptorsFromDi()),
  ]);
