<footer class="footer">
  <div class="footer-links-container">
    <a
      href="https://garmin.com/legal/terms-of-use/"
      target="_blank">
      Terms of Use
    </a>
    <a
      href="https://garmin.com/privacy/aviation/"
      target="_blank">
      Privacy
    </a>
    <a
      href="https://garmin.com/legal/security/"
      target="_blank">
      Security
    </a>
    <div
      class="trust-arc-cookie-preferences"
      id="teconsent"></div>
  </div>
  <span class="copyright">© Garmin Ltd. or its subsidiaries. All rights reserved.</span>
</footer>
