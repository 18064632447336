import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Route,
  RouteReuseStrategy,
} from '@angular/router';
import { IRouteReuse } from '@shared/interfaces/iroute-reuse';

export class AppRouteReuseStrategy implements RouteReuseStrategy {
  private readonly handlers = new Map<Route, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return typeof route.data['cache'] === 'boolean' && route.data['cache'];
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (
      typeof route.data['cache'] === 'boolean' &&
      route.data['cache'] &&
      route.routeConfig != null
    ) {
      if (hasComponentRef(handle) && implementsIRouteReuse(handle.componentRef.instance)) {
        handle.componentRef.instance.handleStore();
      }
      this.handlers.set(route.routeConfig, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig != null && this.handlers.get(route.routeConfig) != null;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const handle = route.routeConfig != null ? this.handlers.get(route.routeConfig) : undefined;
    if (handle == null) {
      return null;
    }

    if (hasComponentRef(handle) && implementsIRouteReuse(handle.componentRef.instance)) {
      handle.componentRef.instance.handleRetrieve();
    }
    return handle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

const implementsIRouteReuse = (obj?: any): obj is IRouteReuse => {
  return obj != null && 'handleRetrieve' in obj && 'handleStore' in obj;
};

const hasComponentRef = (obj?: DetachedRouteHandle): obj is { componentRef: any } => {
  return obj != null && 'componentRef' in obj;
};
